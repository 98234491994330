<template>
  <div>
    <heads :msg="title_msg" :states="1"></heads>
    <div class="success">
      <div class="yimgBox">
        <img class="yimg" src="../../static/giftIndex/y.png" alt="">
      </div>
      <p class="dhzg">兑换成功</p>
      <p class="dhcgMsg">兑换成功后，请耐心等待礼品哦~</p>
      <div class="btns">
        <button class="btnOne" @click="ExchangeRecord()">查看兑换记录</button>
        <!-- <button class="btnTwo">去社区赚筑龙币</button> -->
      </div>
    </div>
    <div class="more">
      <div class="zlTitle">
        <div class="zlTitle_left">
          <p>更多礼品</p>
          <img src="../../static/giftIndex/titBg.png" alt="">
        </div>
      </div>
      <div class="main">
        <img v-for="(item,index) in moreList" :key="index" class="mainImg" :src="item.img" alt="" @click="goDetail(item.id)">
      </div>
    </div>
  </div>
</template>

<script>
import heads from '@/compontens/heads.vue'
import { get_gift } from '@/api/my_user.js'
export default {
  components: {
    heads
  },
  data() {
    return {
      title_msg: '兑换成功',
      moreList: []
    }
  },
  mounted() {
    this.get_msg()
  },
  methods: {
    ExchangeRecord() {
      this.$router.push({
        path: '/ExchangeRecord'
      })
    },
    goDetail(id) {
      console.log(id)
      this.$router.push({
        path: '/gift/giftExchange',
        query: {
          id: id
        }
      })
    },
    get_msg() {
      get_gift().then(res => {
        console.log(res)
        this.moreList = res.result.zlProduct
      })
    }
  },
  beforeRouteEnter(to, from, next) {
    window.document.body.style.backgroundColor = '#f7f7f7'
    next()
  },
  beforeRouteLeave(to, from, next) {
    window.document.body.style.backgroundColor = ''
    next()
  }
}
</script>

<style lang="scss" scoped>
.main{
  width: 92%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 25px;
}
.mainImg{
  width: 330px;
  height: 330px;
  background: cornsilk;
  margin-top: 30px;

}
.more{
  width: 100%;
  height: 100vh;
  background: #fff;
}
.zlTitle_right{
  display: flex;
  p{
    color: #888888;
    padding: 0 15px 0 0;
  }
  img{
    width: 11px;
    height: 18px;
    padding: 9px 0 0 0;
  }
}
.zlTitle{
  display: flex;
  justify-content: space-between;
}
.zlTitle_left{
  width: 141px;
  height: 35px;
  position: relative;
  margin: 0 0 0 31px;
}
.zlTitle_left p{
  font-size: 34px;
  color: #222222;
  font-weight: 600;
}
.zlTitle_left img{
  width: 138px;
  height: 13px;
  position: absolute;
  bottom: -7px;
}
.success{
  width: 100%;
  height: 466px;
  background: #fff;
  margin: 22px 0 22px 0;
  overflow: hidden;
}
.btnOne{
  width: 330px;
  height: 80px;
  border: 1px solid #EE2E2F;
  border-radius: 4px;
  color: #EE2E2F;
  background: #fff;
  margin-left: calc((100% - 330px) / 2);
}
.btns{
  // width: 93%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 48px;
}
.btnTwo{
  width: 330px;
  height: 80px;
  background: #EE2E2F;
  border: 1px solid #EE2E2F;
  border-radius: 4px;
  color: #fff;
}
.yimgBox{
  margin: 0 auto;
  width: 94px;
  height: 94px;
  margin-top: 71px
}
.yimg{
  width: 94px;
  height: 94px;
}
.dhzg{
  text-align: center;
  font-size: 34px;
  font-weight: 600;
  color: #222222;
  padding: 31px 0 0 0;
 }
.dhcgMsg{
  text-align: center;
  font-size: 26px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #888888;
}
</style>
